import React, {useEffect, useState, useContext} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import 'yup-phone'
import Input from '../ui/form/input'
import Select from '../ui/form/select'
import Textarea from '../ui/form/textarea'
import { gsap } from 'gsap'
import { FirebaseContext } from '../firebase'

const ContactSchema = Yup.object().shape({
  budget: Yup.string(),
  name: Yup.string().required('Please fill in your name'),
  company: Yup.string(),
  phone: Yup.string().phone('${path} is not a valid phone number'),
  email: Yup.string().email('The email entered is not valide').required('Please fill in your email'),
  service: Yup.string(),
  description: Yup.string().required('Please give us a brief intro of your project'),
})

const Contact = () => {

  const [sent, setSent] = useState(false)

  const isSent = () =>{
    setSent(true)
  }

  const fb = useContext(FirebaseContext)

  const sendForm = (values) => {
    
    const {budget, name, email, phone, company, service, description } = values

    fb.db.collection("bhtform").add({
        name: name,
        budget: budget,
        email: email,
        phone: phone,
        company: company,
        service: service,
        description: description
    })

    const message = "Notre équipe analyse votre demande. Nous vous contacterons bientôt pour de plus amples discussions."

    const prenom = name

    const subject = "Merci de votre intérêt "

    fetch('https://us-central1-elearning-9cb37.cloudfunctions.net/sendEmail', {
      method: 'POST',
      body: JSON.stringify({email, prenom, subject, message})
    }).then(res => {
      return res.json();
    })
    
  }

  useEffect(() => {
    !sent && gsap.to(formRef.children, {opacity: 1, duration: .8, y: 20, ease: "back", stagger: .1})
    sent && gsap.from(".form-sent", {opacity: 0, duration: .8, y: 20, ease: "back"})
  },)

  let formRef = null

  return(
    <Layout>
      <SEO title="Web & Mobile Development, IT Support, Design" />
      <div className="page-container services">
        <h2 className="page-header color-0 center">Hire us</h2>
        <section id="app-dev">
          <div className="container2">
            <p className="section-body2 color-1">
              Ready to conquer the world 
              <span role="img" aria-labelledby="smile">😃</span>? Tell us a little bit about what you want to achieve, and we will contact you for further details.
            </p>
            {
              sent
              ?
                <div className="form-sent center color-1">
                  <h4>Votre formulaire a été envoyé avec succès <span role="img" aria-labelledby="clap">👏</span>. Nous vous contacterons sous peu. Merci.</h4>
                </div>
              :
              <Formik
                initialValues={{
                  budget: '',
                  name: '',
                  company: '',
                  email: '',
                  phone: '+',
                  service: '',
                  description: ''
                }}
                validationSchema={ContactSchema}
                onSubmit={(values, actions) =>{
                  sendForm(values)
                  actions.resetForm()
                  isSent()
                }}
              >
                {({isSubmitting, isValid, handleReset}) => (
                  <Form className="contact-form" ref={ el => formRef = el}>
                    <Field
                      type='text'
                      name='name'
                      placeholder='Tell us your name please ...'
                      component={Input}
                    />
                    <Field
                      type='phone'
                      name='phone'
                      placeholder="Tell us your phone number ..."
                      component={Input}
                    />
                    <Field
                      type='text'
                      name='company'
                      placeholder="Tell us your company's name please ..."
                      component={Input}
                    />
                    <Field
                      type='email'
                      name='email'
                      placeholder='Fill in your email please ...'
                      component={Input}
                    />
                    <Field 
                      as="select" 
                      name="budget"
                      component={Select}
                      placeholder='What is your budget'
                    >
                      <option value="" disabled>Budget</option>
                      <option value="Less than $1000 US">Less than $1000 US</option>
                      <option value="$1000 - $5000 US">$1000 - $5000 US</option>
                      <option value="$5000 - $10000 US">$5000 - $10000 US</option>
                      <option value="$10000 - $20000 US">$10000 - $20000 US</option>
                      <option value="$20000 - $50000 US">$20000 - $50000 US</option>
                      <option value="More than $50000 US">More than $50000 US</option>
                    </Field>
                    <Field 
                      as="select" 
                      name="service"
                      component={Select}
                      placeholder='What service do you need'
                    >
                      <option value="" disabled>Service</option>
                      <option value="Development">Development</option>
                      <option value="IT Support">IT Support</option>
                      <option value="Design">Design</option>
                      <option value="Data Analysis">Data Analysis</option>
                      <option value="Biomed Engineering">Biomed Engineering</option>
                      <option value="Staff Augmentation">Staff Augmentation</option>
                    </Field>
                    <Field
                      type='text'
                      name='description'
                      placeholder='Tell us briefly about the project ...'
                      component={Textarea}
                    />
                    <div className="center">
                      <button className="mybtn-border center color-2-bg color-1" type='submit'>Let's get to work <span role="img" aria-labelledby="handshake" >🤝</span></button>
                    </div>
                    
                  </Form>           
                )}
              </Formik>
            }
          </div>
        </section>
      </div>
    </Layout> 
  )
}

export default Contact
